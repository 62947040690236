import { useQuery } from '@tanstack/react-query';
import React, { useMemo } from 'react';
import Navbar from '../../../components/Navbar';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import MaterialReactTable from "material-react-table";
import Loaders from '../../../components/Loaders';
import { Switch } from '@mui/material';
import Swal from 'sweetalert2';

export default function GiftCardImage() {
    const axiosPrivate = useAxiosPrivate();
    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success ',
            cancelButton: 'btn btn-danger me-3'
        },
        buttonsStyling: false
    })


    const handleGiftCardImage = () => {
        const res = axiosPrivate.get(`gift-image/all?country_code=dk`)
        return res;
    };
    const { data: allGiftCards = [], isLoading, refetch } = useQuery({
        queryKey: ['get-gift-cards'],
        queryFn: handleGiftCardImage,
        cacheTime: 5000,
    })
    const handleGiftCardImageStatus = async (id, value) => {
        const response = await axiosPrivate.put(`gift-image/is_active/${id}`, {
            is_active: value
        });
        refetch()
        return response;
    }

    const handleMessageCardDelete = async (id) => {
        swalWithBootstrapButtons.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const res = await axiosPrivate.delete(`gift-image/delete/${id}`);
                    if (res.status === 200) {
                        swalWithBootstrapButtons.fire(
                            'Deleted!',
                            'Gift card has been deleted.',
                            'success'
                        );
                        refetch();
                    }
                } catch (error) {
                    console.error('Error deleting the card:', error);
                    swalWithBootstrapButtons.fire(
                        'Error!',
                        'There was a problem deleting the card.',
                        'error'
                    );
                }
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                swalWithBootstrapButtons.fire(
                    'Cancelled',
                    'Your imaginary file is safe :)',
                    'error'
                );
            }
        });
    };


    const columns = useMemo(
        () => [
            {
                accessorFn: (row) => <img src={row.image} alt="Images" height='50px' />,
                header: "Image",
                muiTableHeadCellProps: { sx: { color: "#9C6EAA" } },
                Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong>
            },
            {
                accessorFn: (row) => row.title,
                header: "Name",
                muiTableHeadCellProps: { sx: { color: "#9C6EAA" } },
                Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong>
            },

            {
                accessorFn: (row) => row.price,
                header: "Price",
                muiTableHeadCellProps: { sx: { color: "#9C6EAA" } },
                Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong>
            },
            {
                accessorFn: (row) => row.count,
                header: "Total Uses",
                muiTableHeadCellProps: { sx: { color: "#9C6EAA" } },
                Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong>
            },

            {
                accessorFn: (row) => <Switch defaultChecked={row.is_active} onChange={(e) => handleGiftCardImageStatus(row.id, e.target.checked)} />,
                header: "Status",
                muiTableHeadCellProps: { sx: { color: "#9C6EAA" } },
                Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong>
            },


            {
                accessorFn: (row) => (
                    <>
                        <button onClick={() => handleMessageCardDelete(row.id)} className='btn btn-danger mx-2'><i className="fa-solid fa-trash" ></i></button>
                    </>
                ),
                id: "action", //id required if you use accessorFn instead of accessorKey
                header: "Action",
                Header: <i style={{ color: "red" }}>Action</i> //
            }
        ],
        [],
    );


    return (
        <>
            <Navbar
                title="Gift Card"
                btnTitle="Add New Gift Card"
                btnLink="/admin/dashboard/gift-cards/create"
            />
            {
                isLoading && <div className="d-flex justify-content-center align-items-center">
                    <Loaders />
                </div>
            }



            <div className='container my-5'>


                {allGiftCards?.data?.data &&
                    <>
                        <MaterialReactTable
                            columns={columns}
                            data={allGiftCards?.data?.data}
                        />
                    </>
                }
            </div>
        </>
    )
}
